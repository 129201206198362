<template>
  <div class="pa-5 content-db-style">
    <v-card class="card-style rounded-1">
    <v-card-title class="pa-0">
       <v-row no-gutters>
      <v-col cols="12" sm="4" :align="currentSize >= 2 ? 'left' : 'center'" class="px-5 pt-2">
        <h2 class="mt-1">TOTAL <v-btn class="mt-n1 ml-2" depressed icon @click="initialize()"><v-icon>mdi-refresh</v-icon></v-btn></h2>
      </v-col>
      <v-col cols="12" sm="8" :align="currentSize >= 2 ? 'right' : 'center'" class="px-5 py-2">
          <div class="drp-div-style">
            <date-range-picker class="drp-style" v-model="dateRange" format="MMM D, YYYY" :options="dr_options" />
            <v-icon class="pa-0">mdi-chevron-down</v-icon>
          </div>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    </v-card-title>
    <v-row class="ma-2">
      <v-col cols="12">
        <v-row class="stats-style">
          <v-col cols="12" sm="6" md="3">
            <!--START MEDIA-->
            <div class="my-3">
              <h5>NET PROFIT (EXCL AD COST)</h5>
              <h2>{{formatMoney(data.new_method_total_profit - (data.gads_stats.cost || 0))}}</h2>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.new_method_total_profit - (data.gads_stats.cost || 0))-( data.new_method_total_profit_compared - (data.gads_stats_compared.cost || 0))) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_total_profit - (data.gads_stats.cost || 0), data.new_method_total_profit_compared - (data.gads_stats_compared.cost || 0))}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_total_profit - (data.gads_stats.cost || 0), data.new_method_total_profit_compared - (data.gads_stats_compared.cost || 0))}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.new_method_total_profit_compared - (data.gads_stats_compared.cost || 0)) + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.new_method_total_profit - (data.gads_stats.cost || 0))-(data.new_method_total_profit_compared_st - (data.gads_stats_compared_st.cost || 0))) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange((data.new_method_total_profit - (data.gads_stats.cost || 0)), data.new_method_total_profit_compared_st - (data.gads_stats_compared_st.cost || 0))}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_total_profit - (data.gads_stats.cost || 0), data.new_method_total_profit_compared_st - (data.gads_stats_compared_st.cost || 0))}}%</span>
              </v-chip>
              <span class="chip-text">{{formatMoney(data.new_method_total_profit_compared_st - (data.gads_stats_compared_st.cost || 0))+ " " + data.compared_wording +  " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END MEDIA-->

             <!--START MEDIA-->
            <div class="my-3">
              <h5>PROFIT</h5>
              <h2>{{formatMoney(data.new_method_total_profit)}}</h2>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.profit_amount_compared_change_trend == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.profit_amount_compared_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.profit_amount_compared_change}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.profit_amount_compared) + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.profit_amount_compared_change_trend_st == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.profit_amount_compared_change_st}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.profit_amount_compared_change_st}}%</span>
              </v-chip>
              <span class="chip-text">{{formatMoney(data.profit_amount_compared_st) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>USA-ESTA</h5>
              <h4>{{formatMoney(data.profit_amount_esta)}}</h4>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.profit_amount_compared_esta_change_trend == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.profit_amount_compared_esta_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.profit_amount_compared_esta_change}}%</span>
              </v-chip>

              <span class="chip-text-2">{{formatMoney(data.profit_amount_compared_esta) + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.profit_amount_compared_esta_change_trend_st == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.profit_amount_compared_esta_change_st}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.profit_amount_compared_esta_change_st}}%</span>
              </v-chip>
              <span class="chip-text-2">{{formatMoney(data.profit_amount_compared_esta_st) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>K-ETA</h5>
              <h4>{{formatMoney(data.profit_amount_keta)}}</h4>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.profit_amount_compared_keta_change_trend == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.profit_amount_compared_keta_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.profit_amount_compared_keta_change}}%</span>
              </v-chip>

              <span class="chip-text-2">{{formatMoney(data.profit_amount_compared_keta) + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.profit_amount_compared_keta_change_trend_st == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.profit_amount_compared_keta_change_st}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.profit_amount_compared_keta_change_st}}%</span>
              </v-chip>
              <span class="chip-text-2">{{formatMoney(data.profit_amount_compared_keta_st) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>UK-EVW</h5>
              <h4>{{formatMoney(data.profit_amount_evw)}}</h4>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.profit_amount_compared_evw_change_trend == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.profit_amount_compared_evw_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.profit_amount_compared_evw_change}}%</span>
              </v-chip>

              <span class="chip-text-2">{{formatMoney(data.profit_amount_compared_evw) + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.profit_amount_compared_evw_change_trend_st == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.profit_amount_compared_evw_change_st}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.profit_amount_compared_evw_change_st}}%</span>
              </v-chip>
              <span class="chip-text-2">{{formatMoney(data.profit_amount_compared_evw_st) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>KH-EVISA</h5>
              <h4>{{formatMoney(data.new_method_profit_kh_evisa)}}</h4>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(data.new_method_profit_kh_evisa - data.new_method_profit_compared_kh_evisa) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_profit_kh_evisa,data.new_method_profit_compared_kh_evisa)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_profit_kh_evisa,data.new_method_profit_compared_kh_evisa)}}%</span>
              </v-chip>

              <span class="chip-text-2">{{formatMoney(data.new_method_profit_compared_kh_evisa) + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.new_method_profit_kh_evisa)-(data.new_method_profit_compared_st_kh_evisa)) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_profit_kh_evisa, data.new_method_profit_compared_st_kh_evisa)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_profit_kh_evisa, data.new_method_profit_compared_st_kh_evisa)}}%</span>
              </v-chip>
              <span class="chip-text-2">{{formatMoney(data.new_method_profit_compared_st_kh_evisa) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>VN-EVISA</h5>
              <h4>{{formatMoney(data.new_method_profit_vn_evisa)}}</h4>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(data.new_method_profit_vn_evisa - data.new_method_profit_compared_vn_evisa) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_profit_vn_evisa,data.new_method_profit_compared_vn_evisa)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_profit_vn_evisa,data.new_method_profit_compared_vn_evisa)}}%</span>
              </v-chip>

              <span class="chip-text-2">{{formatMoney(data.new_method_profit_compared_vn_evisa) + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.new_method_profit_vn_evisa)-(data.new_method_profit_compared_st_vn_evisa)) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_profit_vn_evisa, data.new_method_profit_compared_st_vn_evisa)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_profit_vn_evisa, data.new_method_profit_compared_st_vn_evisa)}}%</span>
              </v-chip>
              <span class="chip-text-2">{{formatMoney(data.new_method_profit_compared_st_vn_evisa) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->


            
          </v-col>
            <v-divider class="divider-style"
              vertical
            ></v-divider>
          <v-col cols="12" sm="6" md="3">
            <!--START MEDIA-->
            <div class="my-3">
              <h5>TOTAL REVENUE</h5>
              <h2>{{formatMoney(data.earned)}}</h2>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.earned_compared_change_trend == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.earned_compared_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.earned_compared_change}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.earned_compared) + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison" class="mb-2">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.earned_compared_change_trend_st == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.earned, data.earned_compared_st)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.earned, data.earned_compared_st)}}%</span>
              </v-chip>
              <span class="chip-text">{{formatMoney(data.earned_compared_st) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
              <!-- --------------- -->
              <div>
                <span class="chip-text">{{data.new_method_total_applications_processed_count + " sales"}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.applications_processed_compared_change_trend == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.applications_processed_compared_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.applications_processed_compared_change}}%</span>
              </v-chip>

              <span class="chip-text">{{data.new_method_total_applications_processed_compared_count + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
                <span class="chip-text">{{data.new_method_total_applications_processed_count + " sales"}}</span>
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.applications_processed_compared_change_trend_st == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.applications_processed_compared_change_st}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.applications_processed_compared_change_st}}%</span>
              </v-chip>
              <span class="chip-text">{{(data.applications_processed_compared_count_st || 0) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>USA-ESTA</h5>
              <h4>{{formatMoney(data.earned_esta)}}</h4>
              <div>
                <span class="chip-text-2">{{data.applications_processed_esta_count + " sales"}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.applications_processed_compared_esta_change_trend == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.applications_processed_compared_esta_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.applications_processed_compared_esta_change}}%</span>
              </v-chip>

              <span class="chip-text-2">{{data.applications_processed_compared_esta_count + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <span class="chip-text-2">{{data.applications_processed_esta_count + " sales"}}</span>
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.applications_processed_compared_esta_change_trend_st == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.applications_processed_compared_esta_change_st}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.applications_processed_compared_esta_change_st}}%</span>
              </v-chip>
              <span class="chip-text-2">{{(data.applications_processed_compared_esta_count_st || 0) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>K-ETA</h5>
              <h4>{{formatMoney(data.earned_keta)}}</h4>
              <div>
                <span class="chip-text-2">{{data.applications_processed_keta_count + " sales"}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.applications_processed_compared_keta_change_trend == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.applications_processed_compared_keta_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.applications_processed_compared_keta_change}}%</span>
              </v-chip>

              <span class="chip-text-2">{{data.applications_processed_compared_keta_count + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <span class="chip-text-2">{{data.applications_processed_keta_count + " sales"}}</span>
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.applications_processed_compared_keta_change_trend_st == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.applications_processed_compared_keta_change_st}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.applications_processed_compared_keta_change_st}}%</span>
              </v-chip>
              <span class="chip-text-2">{{(data.applications_processed_compared_keta_count_st || 0) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>UK-EVW</h5>
              <h4>{{formatMoney(data.earned_evw)}}</h4>
              <div>
                <span class="chip-text-2">{{data.applications_processed_evw_count + " sales"}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.applications_processed_compared_evw_change_trend == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.applications_processed_compared_evw_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.applications_processed_compared_evw_change}}%</span>
              </v-chip>

              <span class="chip-text-2">{{data.applications_processed_compared_evw_count + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <span class="chip-text-2">{{data.applications_processed_evw_count + " sales"}}</span>
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.applications_processed_compared_evw_change_trend_st == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.applications_processed_compared_evw_change_st}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.applications_processed_compared_evw_change_st}}%</span>
              </v-chip>
              <span class="chip-text-2">{{(data.applications_processed_compared_evw_count_st || 0) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>KH-EVISA</h5>
              <h4>{{formatMoney(data.new_method_earned_kh_evisa)}}</h4>
              <div>
                <span class="chip-text-2">{{data.new_method_applications_processed_count_kh_evisa + " sales"}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(data.new_method_applications_processed_count_kh_evisa - data.new_method_applications_processed_compared_count_kh_evisa) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_applications_processed_count_kh_evisa,data.new_method_applications_processed_compared_count_kh_evisa)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_applications_processed_count_kh_evisa,data.new_method_applications_processed_compared_count_kh_evisa)}}%</span>
              </v-chip>

              <span class="chip-text-2">{{data.new_method_applications_processed_compared_count_kh_evisa + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <span class="chip-text-2">{{data.new_method_applications_processed_count_kh_evisa + " sales"}}</span>
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.new_method_applications_processed_count_kh_evisa)-(data.new_method_applications_processed_compared_count_st_kh_evisa)) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_applications_processed_count_kh_evisa, data.new_method_applications_processed_compared_count_st_kh_evisa)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_applications_processed_count_kh_evisa, data.new_method_applications_processed_compared_count_st_kh_evisa)}}%</span>
              </v-chip>
              <span class="chip-text-2">{{data.new_method_applications_processed_compared_count_st_kh_evisa + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>VN-EVISA</h5>
              <h4>{{formatMoney(data.new_method_earned_vn_evisa)}}</h4>
              <div>
                <span class="chip-text-2">{{data.new_method_applications_processed_count_vn_evisa + " sales"}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(data.new_method_applications_processed_count_vn_evisa - data.new_method_applications_processed_compared_count_vn_evisa) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_applications_processed_count_vn_evisa,data.new_method_applications_processed_compared_count_vn_evisa)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_applications_processed_count_vn_evisa,data.new_method_applications_processed_compared_count_vn_evisa)}}%</span>
              </v-chip>

              <span class="chip-text-2">{{data.new_method_applications_processed_compared_count_vn_evisa + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <span class="chip-text-2">{{data.new_method_applications_processed_count_vn_evisa + " sales"}}</span>
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.new_method_applications_processed_count_vn_evisa)-(data.new_method_applications_processed_compared_count_st_vn_evisa)) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_applications_processed_count_vn_evisa, data.new_method_applications_processed_compared_count_st_vn_evisa)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_applications_processed_count_vn_evisa, data.new_method_applications_processed_compared_count_st_vn_evisa)}}%</span>
              </v-chip>
              <span class="chip-text-2">{{data.new_method_applications_processed_compared_count_st_vn_evisa + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->
            
          </v-col>
            <v-divider class="divider-style"
              vertical
            ></v-divider>
          <v-col cols="12" sm="6" md="3">

            <!--START MEDIA-->
            <div class="my-3">
              <h5>GOODLE ADS STATS</h5>
              <h5 class="mt-3">AD COST</h5>
              <h2>{{formatMoney(data.gads_stats.cost || 0)}}</h2>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.gads_stats.cost || 0)-(data.gads_stats_compared.cost || 0)) == 'green'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.gads_stats.cost || 0, data.gads_stats_compared.cost || 0)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.gads_stats.cost || 0, data.gads_stats_compared.cost || 0)}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.gads_stats_compared.cost || 0) + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.gads_stats.cost || 0)-(data.gads_stats_compared_st.cost || 0)) == 'green'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.gads_stats.cost || 0, data.gads_stats_compared_st.cost || 0)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.gads_stats.cost || 0, data.gads_stats_compared_st.cost || 0)}}%</span>
              </v-chip>
              <span class="chip-text">{{formatMoney(data.gads_stats_compared_st.cost || 0) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END MEDIA-->

            <!--START MEDIA-->
            <div class="my-3">
              <h5 class="mt-3">CLICKS</h5>
              <h2>{{(data.gads_stats.clicks || 0).toLocaleString()}}</h2>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.gads_stats.clicks || 0)-(data.gads_stats_compared.clicks || 0)) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.gads_stats.clicks || 0, data.gads_stats_compared.clicks || 0)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.gads_stats.clicks || 0, data.gads_stats_compared.clicks || 0)}}%</span>
              </v-chip>

              <span class="chip-text">{{(data.gads_stats_compared.clicks || 0).toLocaleString() + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.gads_stats.clicks || 0)-(data.gads_stats_compared_st.clicks || 0)) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.gads_stats.clicks || 0, data.gads_stats_compared_st.clicks || 0)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.gads_stats.clicks || 0, data.gads_stats_compared_st.clicks || 0)}}%</span>
              </v-chip>
              <span class="chip-text">{{(data.gads_stats_compared_st.clicks || 0).toLocaleString() + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END MEDIA-->

            <!--START MEDIA-->
            <div class="mt-3 flex-items">
              <h5 class="mr-3">AVG CPC</h5>
              <div>
                <span class="chip-text">{{formatMoney(data.gads_stats.cpc || 0)}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(((data.gads_stats.cpc || 0) * 100) - ((data.gads_stats_compared.cpc || 0) * 100)) == 'green'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange((data.gads_stats.cpc || 0) * 100, (data.gads_stats_compared.cpc || 0) * 100)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange((data.gads_stats.cpc || 0) * 100, (data.gads_stats_compared.cpc || 0) * 100)}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.gads_stats_compared.cpc || 0)}}</span>
              </div>
            </div>
            <!--END MEDIA-->

            <!--START MEDIA-->
            <div class="flex-items">
              <h5 class="mr-3">CR</h5>
              <div>
                <span class="chip-text">{{(((data.applications_processed_count / (data.gads_stats.clicks || 0)) || 0) * 100).toFixed(2) + '%'}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((10000 * data.applications_processed_count / (data.gads_stats.clicks || 0))-(10000 * data.applications_processed_compared_count/ (data.gads_stats_compared.clicks || 0))) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(10000*data.applications_processed_count / (data.gads_stats.clicks || 0), 10000 * data.applications_processed_compared_count/ (data.gads_stats_compared.clicks || 0))}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(10000*data.applications_processed_count / (data.gads_stats.clicks || 0), 10000 * data.applications_processed_compared_count/ (data.gads_stats_compared.clicks || 0))}}%</span>
              </v-chip>

              <span class="chip-text">{{(((data.applications_processed_count / (data.gads_stats_compared.clicks || 0)) || 0) * 100).toFixed(2) + '%'}}</span>
              </div>
            </div>
            <!--END MEDIA-->

            <!--START MEDIA-->
            <div class="flex-items">
              <h5 class="mr-3">EPC</h5>
              <div>
                <span class="chip-text">{{formatMoney(data.new_method_total_earned / (data.gads_stats.clicks || 0))}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(((data.gads_stats.cpc || 0) * 100) - ((data.gads_stats_compared.cpc || 0) * 100)) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(100 * data.new_method_total_earned / (data.gads_stats.clicks || 0), 100 * data.new_method_total_earned_compared / (data.gads_stats_compared.clicks || 0))}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(100 * data.new_method_total_earned / (data.gads_stats.clicks || 0), 100 * data.new_method_total_earned_compared / (data.gads_stats_compared.clicks || 0))}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.new_method_total_earned_compared / (data.gads_stats_compared.clicks || 0))}}</span>
              </div>
            </div>
            <!--END MEDIA-->

            <!--START MEDIA-->
            <div class="flex-items">
              <h5 class="mr-3">CPA</h5>
              <div>
                <span class="chip-text">{{formatMoney((data.gads_stats.cost || 0) / data.applications_processed_count)}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(((data.gads_stats.cpc || 0) * 100) - ((data.gads_stats_compared.cpc || 0) * 100)) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(((data.gads_stats.cost || 0) / data.applications_processed_count), (data.gads_stats_compared.cost || 0) / data.applications_processed_compared_count)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(((data.gads_stats.cost || 0) / data.applications_processed_count), (data.gads_stats_compared.cost || 0) / data.applications_processed_compared_count)}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney((data.gads_stats_compared.cost || 0) / data.applications_processed_compared_count)}}</span>
              </div>
            </div>
            <!--END MEDIA-->
            
            <!--START MEDIA-->
            <div class="my-4">
              <h5 class="mr-3">APPLICATIONS QUEUE</h5>
              <v-row class="chip-text mt-1" no-gutters>
                <v-col cols="8">available</v-col>
                <v-col cols="4" align="right" class="pr-7">{{data.count_available_applications}}</v-col>

                <v-col cols="8">pending passport</v-col>
                <v-col cols="4" align="right" class="pr-7">{{data.count_pending_data}}</v-col>
 
                <v-col cols="8">processing</v-col>
                <v-col cols="4" align="right" class="pr-7">{{data.count_processing}}</v-col>

                <v-col cols="8">awaiting response</v-col>
                <v-col cols="4" align="right" class="pr-7">{{data.count_awaiting_response}}</v-col>
                <v-col cols="12"><v-divider></v-divider></v-col>
                <v-col cols="8"><h6 class="chip-text">TOTAL FOR SUBMISSION</h6></v-col>
                <v-col cols="4" align="right" class="pr-7">{{(parseInt(data.count_available_applications) + parseInt(data.count_pending_data) + parseInt(data.count_processing) + parseInt(data.count_awaiting_response))}}</v-col>
              </v-row>
            </div>
            <!--END MEDIA-->
          </v-col>
            <v-divider class="divider-style"
              vertical
            ></v-divider>
          <v-col cols="12" sm="6" md="3">
            <!--START MEDIA-->
            <div class="my-3">
              <h5>OPERATING EXPENSES</h5>
              <h2>{{formatMoney(data.new_method_total_gov_fees + data.new_method_total_transaction_fees)}}</h2>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.gov_fees_amount_compared_change_trend == 'green'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.gov_fees_amount_compared_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.gov_fees_amount_compared_change}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.new_method_total_gov_fees_compared + data.new_method_total_transaction_fees_compared) + " " + data.compared_wording}}</span>
              </div>
            </div>
            <!--END MEDIA-->

            <!--START MEDIA-->
            <div class="flex-items">
              <h5 class="mr-3">TR. FEES</h5>
              <div>
                <span class="chip-text">{{formatMoney(data.new_method_total_transaction_fees || 0)}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(data.new_method_total_transaction_fees, data.new_method_total_transaction_fees_compared) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_total_transaction_fees, data.new_method_total_transaction_fees_compared)}}</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_total_transaction_fees, data.new_method_total_transaction_fees_compared)}}</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.new_method_total_transaction_fees_compared || 0)}}</span>
              </div>
            </div>
            <!--END MEDIA-->

            <!--START MEDIA-->
            <div class="flex-items">
              <h5 class="mr-3">GOV. FEES</h5>
              <div>
                <span class="chip-text">{{formatMoney(data.new_method_total_gov_fees || 0)}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(data.new_method_total_gov_fees, data.new_method_total_gov_fees_compared) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_total_gov_fees, data.new_method_total_gov_fees_compared)}}</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_total_gov_fees, data.new_method_total_gov_fees_compared)}}</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.new_method_total_gov_fees_compared || 0)}}</span>
              </div>
            </div>
            <!--END MEDIA-->

            <!--START MEDIA-->
            <div class="my-3">
              <h5>AFFILIATE COST</h5>
              <h2>{{formatMoney(data.aff_earned || 0)}}</h2>
              <div>
                <span class="chip-text">{{data.aff_applications_processed_count + " sales"}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.aff_applications_processed_compared_change_trend == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.aff_earned_compared_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.aff_earned_compared_change}}%</span>
              </v-chip>

              <span class="chip-text">{{data.aff_applications_processed_compared_count + " " + data.compared_wording}}</span>
              </div>
            </div>
            <!--END MEDIA-->
            
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12"></v-col>
    </v-row>
    </v-card>
    <v-row class="mt-2">
      <v-col cols="12" md="6">
            <v-card class="card-style rounded-1" >
    <v-row class="ma-1">
      <v-col cols="12" class="flex-items text-uppercase">
        <h4>Google Ads Stats Per Product</h4>
      </v-col>
    </v-row>
    <v-card class="mb-5 dt-container" elevation="0" outlined>
    <v-data-table
      dense
      :loading="loading"
       :headers="headers_gads_product"
        :items="loading ? [] : data.gads_stats_table"
        item-key="type"
        :options.sync="options"
        disable-pagination
        hide-default-footer
        class="mobile-dense-table"
    >
    <template v-slot:[`item.impressions`]="{ item }">
      <span class="text-no-wrap">{{formatNumber(item.impressions)}}</span>
    </template>
    <template v-slot:[`item.clicks`]="{ item }">
      <span class="text-no-wrap">{{formatNumber(item.clicks)}}</span>
    </template>
    <template v-slot:[`item.ctr`]="{ item }">
      <span class="text-no-wrap">{{formatNumber((item.ctr || 0) * 100)}}%</span>
    </template>
    <template v-slot:[`item.cost`]="{ item }">
      <span class="text-no-wrap">{{formatMoney(item.cost)}}</span>
    </template>
    <template v-slot:[`item.cpc`]="{ item }">
      <span class="text-no-wrap">{{formatNumber(((item.cost/item.clicks)) || 0)}}</span>
    </template>
    <template v-slot:[`item.cr`]="{ item }">
      <span class="text-no-wrap">{{formatNumber(((item.total_conversions/item.clicks) * 100) || 0)}}%</span>
    </template>
    <template v-slot:[`item.cpa`]="{ item }">
      <span class="text-no-wrap">{{formatMoney((item.cost/item.total_conversions) || 0)}}</span>
    </template>
    </v-data-table>
    </v-card>
    </v-card>
      </v-col>
      <v-col cols="12" md="6">
            <v-card class="card-style rounded-1" >
    <v-row class="ma-1">
      <v-col cols="12" class="flex-items text-uppercase">
        <h4>Detailed Breakdown</h4>
      </v-col>
    </v-row>
    <v-card class="mb-5 dt-container" elevation="0" outlined>
    <v-data-table
      dense
      :loading="loading"
       :headers="headers_gads_breakdown"
        :items="loading ? [] : data.advanced_stats_table"
        item-key="type"
        :options.sync="options"
        disable-pagination
        hide-default-footer
        class="mobile-dense-table"
    >
    <template v-slot:[`item.revenue`]="{ item }">
      <span class="text-no-wrap">{{formatNumber(item.revenue)}}</span>
    </template>
    <template v-slot:[`item.gov_fees`]="{ item }">
      <span class="text-no-wrap">-{{formatNumber(item.gov_fees)}}</span>
    </template>
    <template v-slot:[`item.transaction_fees`]="{ item }">
      <span class="text-no-wrap">-{{formatNumber(item.transaction_fees)}}</span>
    </template>
    <template v-slot:[`item.profit`]="{ item }">
      <span class="text-no-wrap">{{formatMoney(item.profit)}}</span>
    </template>
    <template v-slot:[`item.gads_cost`]="{ item }">
      <span class="text-no-wrap">-{{formatNumber(item.gads_cost)}}</span>
    </template>
    <template v-slot:[`item.net_profit`]="{ item }">
      <span class="text-no-wrap">{{formatMoney(item.net_profit)}}</span>
    </template>
    <template v-slot:[`item.npm`]="{ item }">
      <span class="text-no-wrap">{{formatNumber(item.npm * 100)}}%</span>
    </template>
    <template v-slot:[`item.npa`]="{ item }">
      <span class="text-no-wrap">{{formatNumber(item.npa)}}</span>
    </template>
    </v-data-table>
    </v-card>
    </v-card>
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="12" md="7">
        <v-card class="card-style rounded-1" >
    <v-row class="ma-1">
      <v-col cols="12" class="flex-items">
        <h4>STRIPE DISTRIBUTION ({{active_stripe_accounts_count}} active)</h4>
      </v-col>
    </v-row>
    <v-card class="mb-5 dt-container" elevation="0" outlined>
    <v-data-table
      dense
      :loading="loadingStripe"
       :headers="headers_stripe"
        :items="loading ? [] : data.stripe_stats"
        item-key="mid"
        :options.sync="options"
        disable-pagination
        hide-default-footer
        class="mobile-dense-table"
    >
    <template v-slot:[`item.account`]="{ item }">
      <span class="text-no-wrap font-weight-bold">
        <span class="mr-2">{{item.mid.replace("stripe_", "")}}</span>
          <v-chip class="chip-status-3 rounded mr-2 my-1 font-weight-bold text-wrap" small>
            <span v-if="item.target_weight > 0">{{Math.round(item.current_weight * 100)}}% / {{Math.round(item.target_weight * 100)}}% </span>
            <span v-else>0%</span>
          </v-chip>
          <v-chip v-if="item.last_10_intents == 10" class="chip-status-4 rounded mr-2 my-1 font-weight-bold text-wrap" small>
            <span>{{((item.last_10_paid / item.last_10_intents)*100).toFixed(0) + '%'}}</span>
          </v-chip>
          <v-chip v-else class="chip-status-4 rounded mr-2 my-1 font-weight-bold text-wrap" small>
            <span>{{item.last_10_paid + " / " + item.last_10_intents}}</span>
          </v-chip>
      </span>
    </template>
    <template v-slot:[`item.amount`]="{ item }">
      <div class="text-no-wrap font-weight-bold">
        <span>{{item.total_amount_formatted}}</span>
      </div>
      <!-- <small v-if="parseFloat(item.windshield_usd) > 0 || parseFloat(item.total_disputed) > 0">-{{(parseFloat(item.windshield_usd) + parseFloat(item.total_disputed)).toFixed(2)}}</small> -->
      <small v-if="item.refunded && parseFloat(item.refunded) != 0">{{formatNumber(item.refunded)}}</small>
    </template>
    <template v-slot:[`item.settings`]="{ item }">
      <span class="font-weight-bold" v-if="item.settings !== null">
        <span v-if="item.revenue_limit == 0">
          <v-chip class="chip-status-5 rounded mr-2 my-1 font-weight-bold text-wrap" small>
            <span>DISABLED</span>
          </v-chip>
        </span>
        <span v-else>
          <span v-if="item.all_products == 1">
            <v-chip class="chip-status-1 rounded mr-2 my-1 font-weight-bold text-wrap" small>
              <span>ALL PRODUCTS</span>
            </v-chip>
          </span>
          <span v-else>
            {{item.settings}} <br>
          </span>

          <span v-if="item.revenue_limit > -1">
            <span v-if="item.revenue_limit_reached == 1">
              <v-chip class="chip-status-3 rounded mr-2 my-1 font-weight-bold text-wrap" small>
              <span>[max = {{ parseFloat(item.revenue_limit).toLocaleString() }} REACHED]</span>
            </v-chip>
            </span>
            <span v-else>
              <v-chip class="chip-status-4 rounded mr-2 my-1 font-weight-bold text-wrap" small>
              <span>[max = {{ parseFloat(item.revenue_limit).toLocaleString() }}]</span>
            </v-chip>
            </span>
          </span>
          <span v-else>
            <v-chip class="chip-status-1 rounded mr-2 my-1 font-weight-bold text-wrap" small>
              <span>[unlimited revenue]</span>
            </v-chip>
          </span>

          <span v-if="item.instant_payout_limit > 0">
            <span v-if="item.instant_payout_available >0">
              <v-chip class="chip-status-6 rounded mr-2 my-1 font-weight-bold text-wrap" small>
                <span>[instant = {{parseFloat(item.instant_payout_limit).toLocaleString()}}]</span>
              </v-chip>
            </span>
            <span v-else>
              <v-chip class="chip-status-5 rounded mr-2 my-1 font-weight-bold text-wrap" small>
                <span>[instant = {{ parseFloat(item.stripe_stats.instant_payout_limit).toLocaleString() }} REACHED]</span>
              </v-chip>
            </span>
          </span>
        </span>
      </span>
      <span v-else>
        <v-chip class="chip-status-5 rounded mr-2 my-1 font-weight-bold text-wrap" small>
          <span>DISABLED</span>
        </v-chip>
      </span>
    </template>
    <template v-slot:[`item.nationalities`]="{ item }">
        <span v-if="item.target_nationalities">
            <v-chip v-for="(n, i) in item.target_nationalities.split(',')" :key="i" class="chip-status-1 rounded mr-2 my-1 font-weight-bold text-wrap" small>
              <span>{{n}}</span>
            </v-chip>
        </span>
        <v-chip v-else class="chip-status-1 rounded mr-2 my-1 font-weight-bold text-wrap" small>
          <span>ALL</span>
        </v-chip>
        <span v-if="item.exclude_nationalities">
          <v-chip v-for="(n, i) in item.exclude_nationalities.split(',')" :key="i" class="chip-status-2 rounded mr-2 my-1 font-weight-bold text-wrap" small>
            <span>{{n}}</span>
          </v-chip>
        </span>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-btn
          v-if="$store.state.user.role == 'admin'"
          outlined
          x-small
          class="mr-2"
          color="#166ecf"
          @click="edit(item)"
        >
        <v-icon small class="mr-1">mdi-square-edit-outline</v-icon>
        Edit
        </v-btn>
    </template>
    
    </v-data-table>
    </v-card>
    </v-card>
      </v-col>

      <v-col cols="12" md="5">
        <v-card class="card-style rounded-1" >
    <v-row class="mx-1">
      <v-col cols="3" class="flex-items">
        <h4>DISPUTES</h4>
      </v-col>
      <v-col cols="9">
        <v-tabs v-model="disputes_tab" dense right class="my-1">
            <v-tab class="text-capitalize">
              Nationalities
            </v-tab>
            <v-tab class="text-capitalize">
              Accounts
            </v-tab>
          </v-tabs>
      </v-col>
    </v-row>
    <v-card class="mb-5 dt-container" elevation="0" outlined>
      <v-tabs-items v-model="disputes_tab">
        <v-tab-item>
          <v-data-table
              dense
              :loading="loading"
              :headers="headers_nationality"
                :items="loading ? [] : nationality_stats"
                item-key="country"
                :options.sync="options"
                disable-pagination
                hide-default-footer
                class="mobile-dense-table"
            >
                <template slot="body.append">
                  <tr style="background-color: #ddd;">
                    <td v-for="h in headers_nationality" :key="h.country" :align="h.value == 'country' ? 'left' : 'right'">
                      {{getTotalColumn1(h.value)}}
                    </td>
                  </tr>
                </template>
            </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-data-table
              dense
              :loading="loading"
              :headers="headers_dba"
                :items="loading ? [] : data.disputes_by_account"
                item-key="mid"
                :options.sync="options"
                disable-pagination
                hide-default-footer
                class="mobile-dense-table"
            >
                <template slot="body.append">
                  <tr style="background-color: #ddd;" v-if="data.disputes_by_account && data.disputes_by_account.length > 0">
                    <td v-for="h in headers_dba" :key="h.mid" :align="h.value == 'mid' ? 'left' : 'right'">
                      {{getTotalColumn2(h.value)}}
                    </td>
                  </tr>
                </template>
            </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    </v-card>
      </v-col>
    </v-row>
    <v-overlay
      :absolute="true"
      :value="loading"
    >
      <v-progress-circular
        indeterminate
        size="74"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog
      v-model="dialogForm"
      width="800"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title>
          <strong v-if="action == 'add'">Add Stripe Account</strong>
          <strong v-else-if="action == 'edit'">Update Stripe Account</strong>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="closeFormDialog()"
          >
          <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-2" style="max-height: 900px;">
            <v-form ref="formRef">
              <v-row no-gutters>
                <v-col cols="12">
                    <div class="px-0 pb-1 text-subtitle-1">Stripe Account</div>
                    <span class="flex-items justify-center">
                    <v-text-field
                    v-if="action == 'add'"
                    v-model="formdata.name"
                    color="grey"
                    :rules="[formRules.required]"
                    dense
                    height="42"
                    outlined
                    :append-icon="account_available == true ? 'mdi-check-circle' : account_available == false ? 'mdi-close-circle' : ''"
                    ></v-text-field>
                    <v-text-field
                    v-else
                    :value="formdata.name"
                    color="grey"
                    dense
                    height="42"
                    disabled
                    outlined
                    ></v-text-field>
                    <v-btn
                      v-if="action == 'add'"
                      color="grey"
                      class="white--text mb-6 ml-1"
                      depressed
                      height="42"
                      @click="checkAvailability()"
                      :loading="loadingAvailable"
                    >
                      Check Availablility
                    </v-btn>
                    </span>
                </v-col>
                <!-- <v-col cols="12">
                    <div class="px-0 pb-1 text-subtitle-1">Stripe PK</div>
                    <v-text-field
                    v-if="action == 'add'"
                    v-model="formdata.stripe_pk"
                    color="grey"
                    :rules="[formRules.required]"
                    height="42"
                    dense
                    outlined
                    ></v-text-field>
                    <v-text-field
                    v-else
                    :value="formdata.stripe_pk"
                    color="grey"
                    dense
                    height="42"
                    disabled
                    outlined
                    ></v-text-field>
                </v-col> -->
                <!-- <v-col cols="12">
                    <div class="px-0 pb-1 text-subtitle-1">Stripe SK</div>
                    <v-text-field
                    v-if="action == 'add'"
                    v-model="formdata.stripe_sk"
                    color="grey"
                    :rules="[formRules.required]"
                    height="42"
                    dense
                    outlined
                    ></v-text-field>
                    <v-text-field
                    v-else
                    :value="formdata.stripe_sk"
                    color="grey"
                    dense
                    height="42"
                    disabled
                    outlined
                    ></v-text-field>
                </v-col> -->
                <!-- <v-col cols="12">
                    <div class="px-0 pb-1 text-subtitle-1">Stripe Accnt ID</div>
                    <span class="flex-items">
                    <v-text-field
                    v-if="action == 'add'"
                    v-model="formdata.stripe_account_id"
                    color="grey"
                    :rules="[formRules.required]"
                    height="42"
                    dense
                    outlined
                    ></v-text-field>
                    <v-text-field
                    v-else
                    :value="formdata.stripe_account_id"
                    color="grey"
                    dense
                    height="42"
                    disabled
                    outlined
                    ></v-text-field>
                    <v-btn
                      v-if="action == 'add'"
                      color="grey"
                      class="white--text mb-6 ml-1"
                      depressed
                      height="42"
                      @click="getStripeAccountLiveDetails()"
                      :loading="loadingGet"
                    >
                      GET
                    </v-btn>
                    </span>
                </v-col> -->
                <v-col cols="12">
                    <div class="px-0 pb-2 text-subtitle-1">Max Revenue</div>
                    <div class="flex-items">
                      <v-autocomplete
                      v-model="formdata.max_revenue_type"
                      @change="changeRevenue(formdata.max_revenue_type)"
                      color="grey"
                      :rules="[formRules.required]"
                      height="42"
                      :items="max_revenue_list"
                      item-text="desc"
                      item-value="val"
                      outlined
                      ></v-autocomplete>
                      <v-text-field
                      v-if="show_custom"
                      v-model="formdata.max_revenue_amount"
                      color="grey"
                      class="ml-1"
                      :rules="[formRules.required]"
                      placeholder="Max Revenue Amount"
                      height="42"
                      outlined
                      ></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="px-0 pb-1 text-subtitle-1">Weight</div>
                    <v-text-field
                    v-model="formdata.weight"
                    color="grey"
                    :rules="[formRules.required, v => (v >= 0 && v <= 100) || 'Must be a number 0-100']"
                    height="42"
                    type="number"
                    dense
                    outlined
                    @keypress.native="onlyAllowNumbers"
                    min="0"
                    max="100"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" v-if="show_services">
                  <v-sheet color="grey lighten-4" class="pa-3 mb-3 rounded">
                    <div class="px-0 pb-0 text-subtitle-1">Services</div>
                    <v-row no-gutters class="pa-3">
                    <v-col cols="12" sm="6" md="3" v-for="(val, mid) in formdata.mids" :key="mid + '_' + val" class="text-no-wrap py-0">
                      <v-checkbox :label="mid" v-model="formdata.mids[mid]" hide-details></v-checkbox>
                    </v-col>
                    </v-row>
                </v-sheet>
                </v-col>
                <v-col cols="12">
                  <v-sheet color="grey lighten-4" class="pa-3 mb-3 rounded">
                    <div class="px-0 pb-0 text-subtitle-1">Target Nationalities</div>
                    <v-row no-gutters class="pa-3">
                    <v-col cols="12" sm="6" md="3" v-for="(val, n) in formdata.target_nationalities" :key="n + '_' + val" class="text-no-wrap py-0">
                      <v-checkbox :label="n.replaceAll('_', ' ')" v-model="formdata.target_nationalities[n]" hide-details></v-checkbox>
                    </v-col>
                    </v-row>
                </v-sheet>
                </v-col>
                <v-col cols="12">
                  <v-sheet color="grey lighten-4" class="pa-3 mb-3 rounded">
                    <div class="px-0 pb-0 text-subtitle-1">Exclude Nationalities</div>
                    <v-row no-gutters class="pa-3">
                    <v-col cols="12" sm="6" md="3" v-for="(val, n) in formdata.exclude_nationalities" :key="n + '_' + val" class="text-no-wrap py-0">
                      <v-checkbox :label="n.replaceAll('_', ' ')" v-model="formdata.exclude_nationalities[n]" hide-details></v-checkbox>
                    </v-col>
                    </v-row>
                </v-sheet>
                </v-col>
                <v-col cols="12" v-if="show_instant_payout">
                    <div class="px-0 pb-2 text-subtitle-1">Instant Payout</div>
                    <div class="flex-items">
                      <v-autocomplete
                      v-model="formdata.instant_payout_type"
                      color="grey"
                      @change="showInstant(formdata.instant_payout_type)"
                      :rules="[formRules.required]"
                      height="42"
                      :items="instant_payout_list"
                      item-text="desc"
                      item-value="val"
                      outlined
                      ></v-autocomplete>
                      <v-text-field
                      v-if="show_instant"
                      v-model="formdata.instant_payout_limit"
                      color="grey"
                      class="ml-1"
                      :rules="[formRules.required]"
                      placeholder="Instant Payout Limit"
                      height="42"
                      outlined
                      ></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12" v-if="action == 'edit'">
                  <v-sheet color="grey lighten-4" class="mb-3 rounded">
                    <v-row no-gutters class="px-3">
                    <v-col cols="12" class="py-0 pb-5">
                    <v-checkbox label="Show in accounts list" v-model="formdata.show_in_accounts_list" hide-details></v-checkbox>
                    </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
                <v-col cols="12" v-if="action == 'add'">
                  <v-sheet color="grey lighten-4" class="mb-3 rounded">
                    <v-row no-gutters class="px-3">
                    <v-col cols="12" class="py-0 pb-5">
                      <v-checkbox label="Add to disputes table (as own)" v-model="formdata.add_to_disputes" hide-details></v-checkbox>
                    </v-col>
                    <v-col cols="12" class="py-0" v-if="formdata.add_to_disputes">
                      <div class="px-0 pb-0 text-subtitle-1">Company Name</div>
                      <v-text-field
                      v-model="formdata.company_name"
                      color="grey"
                      class="ml-1"
                      :rules="[formRules.required]"
                      height="42"
                      outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0" v-if="formdata.add_to_disputes">
                      <div class="px-0 pb-0 text-subtitle-1">Safepage</div>
                      <v-text-field
                      v-model="formdata.safepage"
                      color="grey"
                      class="ml-1"
                      :rules="[formRules.required]"
                      height="42"
                      outlined
                      placeholder="example.com"
                      ></v-text-field>
                    </v-col>
                    </v-row>
                </v-sheet>
                </v-col>
              </v-row>
            </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outlined
            class="mr-2"
            depressed
            @click="closeFormDialog()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="#166ecf"
            class="white--text mr-3"
            depressed
            @click="confirmUpdateStripeAccount()"
            v-if="action == 'edit'"
            :loading="loadingForm"
          >
          <v-icon class="mr-1">mdi-square-edit-outline</v-icon>
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LineChartDown from '../../assets/icon/line-chart-down.svg'
import LineChartUp from '../../assets/icon/line-chart-up.svg'
import moment from "moment-timezone";
  export default {
    componenents: {
      LineChartDown,
      LineChartUp,
    },
    data: () => ({
        mini: false,
        startDate: null,
        endDate: null,
        search: '',
        headers_stripe: [
            { text: 'ACCOUNT', value: 'account', class: 'text-no-wrap px-2' },
            { text: 'AMOUNT', value: 'amount', align: 'end', class: 'text-no-wrap px-2'},
            { text: 'SETTINGS', value: 'settings', class: 'text-no-wrap px-2'},
            { text: 'NATIONALITIES', value: 'nationalities', class: 'text-no-wrap px-2'},
            { text: 'ACTION', value: 'action', class: 'text-no-wrap px-2'},
          ],
        headers_gads_product: [
            { text: 'PRODUCT', value: 'type', class: 'text-no-wrap px-2' },
            { text: 'IMPR.', value: 'impressions', align: 'end', class: 'text-no-wrap px-2'},
            { text: 'CLICKS', value: 'clicks', align: 'end', class: 'text-no-wrap px-2'},
            { text: 'CTR', value: 'ctr', align: 'end', class: 'text-no-wrap px-2'},
            { text: 'COST', value: 'cost', align: 'end', class: 'text-no-wrap px-2'},
            { text: 'CPC', value: 'cpc', align: 'end', class: 'text-no-wrap px-2'},
            { text: 'CR', value: 'cr', align: 'end', class: 'text-no-wrap px-2'},
            { text: 'CPA', value: 'cpa', align: 'end', class: 'text-no-wrap px-2'},
          ],
        headers_gads_breakdown: [
            { text: 'PRODUCT', value: 'type', class: 'text-no-wrap px-2' },
            { text: 'CONV.', value: 'conversions', align: 'end', class: 'text-no-wrap px-2'},
            { text: 'REVENUE', value: 'revenue', align: 'end', class: 'text-no-wrap px-2'},
            { text: 'GOV.', value: 'gov_fees', align: 'end', class: 'text-no-wrap px-2'},
            { text: 'TRANS.', value: 'transaction_fees', align: 'end', class: 'text-no-wrap px-2'},
            { text: 'PROFIT', value: 'profit', align: 'end', class: 'text-no-wrap px-2'},
            { text: 'GADS', value: 'gads_cost', align: 'end', class: 'text-no-wrap px-2'},
            { text: 'NET PROFIT', value: 'net_profit', align: 'end', class: 'text-no-wrap px-2'},
            { text: 'NPM%', value: 'npm', align: 'end', class: 'text-no-wrap px-2'},
            { text: 'NPA', value: 'npa', align: 'end', class: 'text-no-wrap px-2'},
          ],
          headers_nationality: [
          ],
          headers_dba: [
            { text: 'ACCOUNT', value: 'mid', class: 'text-no-wrap px-2' },
            { text: 'DISPUTES', value: 'disputes_count', class: 'text-no-wrap px-2', align: 'end' },
          ],
        data: {
            "compared_wording": "...",
            "debug_total": "",
            "debug_keta": "",
            "debug_esta": "",
            "applications_processed_count": 0,
            "applications_processed_keta_count": 0,
            "applications_processed_esta_count": 0,
            "applications_processed_evw_count": 0,
            "applications_processed_compared_count": 0,
            "applications_processed_compared_keta_count": 0,
            "applications_processed_compared_esta_count": 0,
            "applications_processed_compared_evw_count": 0,
            "applications_processed_compared_change": "0.00",
            "applications_processed_compared_change_trend": "red",
            "applications_processed_compared_keta_change": "0.00",
            "applications_processed_compared_keta_change_trend": "red",
            "applications_processed_compared_esta_change": "0.00",
            "applications_processed_compared_esta_change_trend": "red",
            "applications_processed_compared_evw_change": "0.00",
            "applications_processed_compared_evw_change_trend": "red",
            "earned": "0.00",
            "earned_esta": "0.00",
            "earned_keta": "0.00",
            "earned_evw": "0.00",
            "earned_compared": "0.00",
            "earned_compared_keta": "0.00",
            "earned_compared_esta": "0.00",
            "earned_compared_evw": "0.00",
            "earned_compared_change": "0.00",
            "earned_compared_change_trend": "red",
            "aff_applications_processed_count": 0,
            "aff_applications_processed_count_esta": 0,
            "aff_applications_processed_count_keta": 0,
            "aff_applications_processed_count_evw": 0,
            "aff_applications_processed_compared_count": 0,
            "aff_applications_processed_compared_keta_count": 0,
            "aff_applications_processed_compared_esta_count": 0,
            "aff_applications_processed_compared_evw_count": 0,
            "aff_applications_processed_compared_change": "0.00",
            "aff_applications_processed_compared_change_trend": "red",
            "aff_applications_processed_compared_keta_change": "0.00",
            "aff_applications_processed_compared_keta_change_trend": "red",
            "aff_applications_processed_compared_esta_change": "0.00",
            "aff_applications_processed_compared_esta_change_trend": "red",
            "aff_applications_processed_compared_evw_change": "0.00",
            "aff_applications_processed_compared_evw_change_trend": "red",
            "aff_earned_compared_change": "0.00",
            "aff_earned_compared_change_trend": "red",
            "aff_earned_compared_keta_change": "0.00",
            "aff_earned_compared_keta_change_trend": "red",
            "aff_earned_compared_esta_change": "0.00",
            "aff_earned_compared_esta_change_trend": "red",
            "aff_earned_compared_evw_change": "0.00",
            "aff_earned_compared_evw_change_trend": "red",
            "gov_fees_amount": "0.00",
            "gov_fees_amount_esta": "0.00",
            "gov_fees_amount_keta": "0.00",
            "gov_fees_amount_evw": "0.00",
            "gov_fees_amount_compared": "0.00",
            "gov_fees_amount_compared_change": "0.00",
            "gov_fees_amount_compared_change_trend": "red",
            "gov_fees_amount_compared_keta": "0.00",
            "gov_fees_amount_compared_keta_change": "0.00",
            "gov_fees_amount_compared_keta_change_trend": "red",
            "gov_fees_amount_compared_esta": "0.00",
            "gov_fees_amount_compared_esta_change": "0.00",
            "gov_fees_amount_compared_esta_change_trend": "red",
            "gov_fees_amount_compared_evw": "0.00",
            "gov_fees_amount_compared_evw_change": "0.00",
            "gov_fees_amount_compared_evw_change_trend": "red",
            "profit_amount": "0.00",
            "profit_amount_esta": "0.00",
            "profit_amount_keta": "0.00",
            "profit_amount_evw": "0.00",
            "profit_amount_compared": "0.00",
            "profit_amount_compared_change": "0.00",
            "profit_amount_compared_change_trend": "red",
            "profit_amount_compared_keta": "0.00",
            "profit_amount_compared_keta_change": "0.00",
            "profit_amount_compared_keta_change_trend": "red",
            "profit_amount_compared_esta": "0.00",
            "profit_amount_compared_esta_change": "0.00",
            "profit_amount_compared_esta_change_trend": "red",
            "profit_amount_compared_evw": "0.00",
            "profit_amount_compared_evw_change": "0.00",
            "profit_amount_compared_evw_change_trend": "red",
            "same_time_comparison": true,
            "stats": {
                "applications_processed_compared_count_st": "0",
                "applications_processed_compared_esta_count_st": "0",
                "applications_processed_compared_keta_count_st": "0",
                "applications_processed_compared_evw_count_st": "0",
                "earned_compared_st": null,
                "earned_compared_esta_st": null,
                "earned_compared_keta_st": null,
                "earned_compared_evw_st": null,
                "aff_applications_processed_compared_count_st": "0",
                "aff_applications_processed_compared_esta_count_st": "0",
                "aff_applications_processed_compared_keta_count_st": "0",
                "aff_applications_processed_compared_evw_count_st": "0",
                "aff_earned_compared_st": 0,
                "aff_earned_compared_esta_st": 0,
                "aff_earned_compared_keta_st": 0,
                "aff_earned_compared_evw_st": 0,
                "transaction_fees_compared_st": null,
                "transaction_fees_compared_esta_st": null,
                "transaction_fees_compared_keta_st": null,
                "transaction_fees_compared_evw_st": null
            },
            "stats_sql": "",
            "check_time": "...",
            "gov_fees_amount_compared_st": null,
            "gov_fees_amount_compared_esta_st": null,
            "gov_fees_amount_compared_keta_st": null,
            "gov_fees_amount_compared_evw_st": null,
            "profit_amount_compared_st": "0",
            "profit_amount_compared_esta_st": "0",
            "profit_amount_compared_keta_st": "0",
            "profit_amount_compared_evw_st": "0",
            "profit_amount_compared_change_st": "0",
            "profit_amount_compared_change_trend_st": "red",
            "profit_amount_compared_esta_change_st": "0",
            "profit_amount_compared_esta_change_trend_st": "red",
            "profit_amount_compared_keta_change_st": "0",
            "profit_amount_compared_keta_change_trend_st": "red",
            "profit_amount_compared_evw_change_st": "0",
            "profit_amount_compared_evw_change_trend_st": "red",
            "applications_processed_compared_change_st": "0",
            "applications_processed_compared_change_trend_st": "red",
            "applications_processed_compared_keta_change_st": "0",
            "applications_processed_compared_keta_change_trend_st": "red",
            "applications_processed_compared_esta_change_st": "0",
            "applications_processed_compared_esta_change_trend_st": "red",
            "applications_processed_compared_evw_change_st": "0",
            "applications_processed_compared_evw_change_trend_st": "red",
            "gads_stats_compared_st": {
                "id": null,
                "cost": 0,
                "clicks": 0,
                "conversions": 0,
                "updated_at_timestamp": ""
            },
            "new_method_total_app_type": 0,
            "new_method_app_type_esta": "USA-ESTA",
            "new_method_total_applications_processed_count": 0,
            "new_method_applications_processed_count_esta": "0",
            "new_method_total_applications_processed_compared_count": 0,
            "new_method_applications_processed_compared_count_esta": "0",
            "new_method_total_applications_processed_compared_count_st": 0,
            "new_method_applications_processed_compared_count_st_esta": "0",
            "new_method_total_earned": 0,
            "new_method_earned_esta": 0,
            "new_method_total_earned_compared": 0,
            "new_method_earned_compared_esta": 0,
            "new_method_total_earned_compared_st": 0,
            "new_method_earned_compared_st_esta": 0,
            "new_method_total_aff_applications_processed_count": 0,
            "new_method_aff_applications_processed_count_esta": "0",
            "new_method_total_aff_applications_processed_compared_count": 0,
            "new_method_aff_applications_processed_compared_count_esta": "0",
            "new_method_total_aff_applications_processed_compared_count_st": 0,
            "new_method_aff_applications_processed_compared_count_st_esta": "0",
            "new_method_total_aff_earned": 0,
            "new_method_aff_earned_esta": 0,
            "new_method_total_aff_earned_compared": 0,
            "new_method_aff_earned_compared_esta": 0,
            "new_method_total_aff_earned_compared_st": 0,
            "new_method_aff_earned_compared_st_esta": 0,
            "new_method_total_transaction_fees": 0,
            "new_method_transaction_fees_esta": 0,
            "new_method_total_transaction_fees_compared": 0,
            "new_method_transaction_fees_compared_esta": 0,
            "new_method_total_transaction_fees_compared_st": 0,
            "new_method_transaction_fees_compared_st_esta": 0,
            "new_method_total_gov_fees": 0,
            "new_method_gov_fees_esta": "0",
            "new_method_total_gov_fees_compared": 0,
            "new_method_gov_fees_compared_esta": "0",
            "new_method_total_gov_fees_compared_st": 0,
            "new_method_gov_fees_compared_st_esta": "0",
            "new_method_total_profit": 0,
            "new_method_profit_esta": 0,
            "new_method_total_profit_compared": 0,
            "new_method_profit_compared_esta": 0,
            "new_method_total_profit_compared_st": 0,
            "new_method_profit_compared_st_esta": 0,
            "new_method_total_stats_timeframe": 0,
            "new_method_stats_timeframe_esta": "",
            "new_method_total_compared_timeframe": 0,
            "new_method_compared_timeframe_esta": "",
            "new_method_total_compared_timeframe_st": 0,
            "new_method_compared_timeframe_st_esta": "",
            "new_method_total_now_timestamp_db": 0,
            "new_method_now_timestamp_db_esta": "",
            "new_method_total_now_timestamp_server": 0,
            "new_method_now_timestamp_server_esta": "",
            "new_sql_USA-ESTA": "",
            "new_method_app_type_keta": "K-ETA",
            "new_method_applications_processed_count_keta": "0",
            "new_method_applications_processed_compared_count_keta": "0",
            "new_method_applications_processed_compared_count_st_keta": "0",
            "new_method_earned_keta": 0,
            "new_method_earned_compared_keta": 0,
            "new_method_earned_compared_st_keta": 0,
            "new_method_aff_applications_processed_count_keta": "0",
            "new_method_aff_applications_processed_compared_count_keta": "0",
            "new_method_aff_applications_processed_compared_count_st_keta": "0",
            "new_method_aff_earned_keta": 0,
            "new_method_aff_earned_compared_keta": 0,
            "new_method_aff_earned_compared_st_keta": 0,
            "new_method_transaction_fees_keta": 0,
            "new_method_transaction_fees_compared_keta": 0,
            "new_method_transaction_fees_compared_st_keta": 0,
            "new_method_gov_fees_keta": "0",
            "new_method_gov_fees_compared_keta": "0",
            "new_method_gov_fees_compared_st_keta": "0",
            "new_method_profit_keta": 0,
            "new_method_profit_compared_keta": 0,
            "new_method_profit_compared_st_keta": 0,
            "new_method_stats_timeframe_keta": "",
            "new_method_compared_timeframe_keta": "",
            "new_method_compared_timeframe_st_keta": "",
            "new_method_now_timestamp_db_keta": "",
            "new_method_now_timestamp_server_keta": "",
            "new_sql_K-ETA": "",
            "new_method_app_type_evw": "UK-EVW",
            "new_method_applications_processed_count_evw": "0",
            "new_method_applications_processed_compared_count_evw": "0",
            "new_method_applications_processed_compared_count_st_evw": "0",
            "new_method_earned_evw": 0,
            "new_method_earned_compared_evw": 0,
            "new_method_earned_compared_st_evw": 0,
            "new_method_aff_applications_processed_count_evw": "0",
            "new_method_aff_applications_processed_compared_count_evw": "0",
            "new_method_aff_applications_processed_compared_count_st_evw": "0",
            "new_method_aff_earned_evw": 0,
            "new_method_aff_earned_compared_evw": 0,
            "new_method_aff_earned_compared_st_evw": 0,
            "new_method_transaction_fees_evw": 0,
            "new_method_transaction_fees_compared_evw": 0,
            "new_method_transaction_fees_compared_st_evw": 0,
            "new_method_gov_fees_evw": "0",
            "new_method_gov_fees_compared_evw": "0",
            "new_method_gov_fees_compared_st_evw": "0",
            "new_method_profit_evw": 0,
            "new_method_profit_compared_evw": 0,
            "new_method_profit_compared_st_evw": 0,
            "new_method_stats_timeframe_evw": "",
            "new_method_compared_timeframe_evw": "",
            "new_method_compared_timeframe_st_evw": "",
            "new_method_now_timestamp_db_evw": "",
            "new_method_now_timestamp_server_evw": "",
            "new_sql_UK-EVW": "",
            "new_method_app_type_kh_evisa": "KH-EVISA",
            "new_method_applications_processed_count_kh_evisa": "0",
            "new_method_applications_processed_compared_count_kh_evisa": "0",
            "new_method_applications_processed_compared_count_st_kh_evisa": "0",
            "new_method_earned_kh_evisa": 0,
            "new_method_earned_compared_kh_evisa": 0,
            "new_method_earned_compared_st_kh_evisa": 0,
            "new_method_aff_applications_processed_count_kh_evisa": "0",
            "new_method_aff_applications_processed_compared_count_kh_evisa": "0",
            "new_method_aff_applications_processed_compared_count_st_kh_evisa": "0",
            "new_method_aff_earned_kh_evisa": 0,
            "new_method_aff_earned_compared_kh_evisa": 0,
            "new_method_aff_earned_compared_st_kh_evisa": 0,
            "new_method_transaction_fees_kh_evisa": 0,
            "new_method_transaction_fees_compared_kh_evisa": 0,
            "new_method_transaction_fees_compared_st_kh_evisa": 0,
            "new_method_gov_fees_kh_evisa": "0",
            "new_method_gov_fees_compared_kh_evisa": "0",
            "new_method_gov_fees_compared_st_kh_evisa": "0",
            "new_method_profit_kh_evisa": 0,
            "new_method_profit_compared_kh_evisa": 0,
            "new_method_profit_compared_st_kh_evisa": 0,
            "new_method_stats_timeframe_kh_evisa": "",
            "new_method_compared_timeframe_kh_evisa": "",
            "new_method_compared_timeframe_st_kh_evisa": "",
            "new_method_now_timestamp_db_kh_evisa": "",
            "new_method_now_timestamp_server_kh_evisa": "",
            "new_sql_KH-EVISA": "",
            "new_method_app_type_vn_evisa": "VN-EVISA",
            "new_method_applications_processed_count_vn_evisa": "0",
            "new_method_applications_processed_compared_count_vn_evisa": "0",
            "new_method_applications_processed_compared_count_st_vn_evisa": "0",
            "new_method_earned_vn_evisa": 0,
            "new_method_earned_compared_vn_evisa": 0,
            "new_method_earned_compared_st_vn_evisa": 0,
            "new_method_aff_applications_processed_count_vn_evisa": "0",
            "new_method_aff_applications_processed_compared_count_vn_evisa": "0",
            "new_method_aff_applications_processed_compared_count_st_vn_evisa": "0",
            "new_method_aff_earned_vn_evisa": 0,
            "new_method_aff_earned_compared_vn_evisa": 0,
            "new_method_aff_earned_compared_st_vn_evisa": 0,
            "new_method_transaction_fees_vn_evisa": 0,
            "new_method_transaction_fees_compared_vn_evisa": 0,
            "new_method_transaction_fees_compared_st_vn_evisa": 0,
            "new_method_gov_fees_vn_evisa": "0",
            "new_method_gov_fees_compared_vn_evisa": "0",
            "new_method_gov_fees_compared_st_vn_evisa": "0",
            "new_method_profit_vn_evisa": 0,
            "new_method_profit_compared_vn_evisa": 0,
            "new_method_profit_compared_st_vn_evisa": 0,
            "new_method_stats_timeframe_vn_evisa": "",
            "new_method_compared_timeframe_vn_evisa": "",
            "new_method_compared_timeframe_st_vn_evisa": "",
            "new_method_now_timestamp_db_vn_evisa": "",
            "new_method_now_timestamp_server_vn_evisa": "",
            "new_sql_VN-EVISA": "",
            "stripe_stats_sql": "",
            "stripe_stats": [],
            "count_available_applications": "0",
            "count_pending_passport": "0",
            "count_awaiting_response": "0",
            "count_processing": "0",
            "gads_stats": {},
            "gads_stats_compared": {}
        },
        perPageChoices: [
            {text:'5' , value: 5},
            {text:'10' , value: 10},
            {text:'20' , value: 20},
            {text:'50' , value: 50},
            {text:'100' , value: 100},
            {text:'250' , value: 250},
            {text:'500' , value: 500},
        ],
        options: {},
        paginationData: {},
        loading: false,
        active_stripe_accounts_count: 0,
        dateRange: [],
        dr_options: {
          ranges: {
          'Today': [moment().tz('America/New_York'), moment().tz('America/New_York')],
          'Yesterday': [moment().tz('America/New_York').subtract(1, 'days'), moment().tz('America/New_York').subtract(1, 'days')],
          'Last 7 Days': [moment().tz('America/New_York').subtract(6, 'days'), moment().tz('America/New_York')],
          'Last 30 Days': [moment().tz('America/New_York').subtract(29, 'days'), moment().tz('America/New_York')],
          'This Month': [moment().tz('America/New_York').startOf('month'), moment().tz('America/New_York').endOf('month')],
          'Last Month': [moment().tz('America/New_York').subtract(1, 'month').startOf('month'), moment().tz('America/New_York').subtract(1, 'month').endOf('month')]
          },
          "buttonClasses": "btn btn-lg",
          "applyButtonClasses": "btn-primary"
          },
      edit_item: {},
      dialogForm: false,
      formdata: {},
      action: '',
      max_revenue_list: [
        {val: 'disabled', desc: '0 (Account disabled)'},
        {val: 'unlimited', desc: 'Unlimited'},
        {val: 'custom', desc: 'Custom'},
      ],
      instant_payout_list: [
        {val: 'disabled', desc: 'Disabled (No priority)'},
        {val: 'enabled', desc: 'Enabled (Prioritize account)'},
      ],
      loadingForm: false,
      mids: {},
      target_nationalities: {},
      exclude_nationalities: {},
      show_custom: false,
      show_instant: false,
      show_instant_payout: false,
      show_services: false,
      nationality_stats: [],
      disputes_tab: 0,
      loadingStripe: false,
    }),
    computed: {
      currentSize() {
        const breakpoint = this.$vuetify.breakpoint;
        if (breakpoint.xs) return 1;
        if (breakpoint.sm) return 2;
        if (breakpoint.md) return 3;
        if (breakpoint.lg) return 4;
        if (breakpoint.xl) return 5;
        return 0;
      }
    },
    watch: {
      dateRange: {
        handler(data){
          if(data.length == 2){
          var date1 = new Date(data[0]);
          this.startDate = parseInt(date1.getDate()) + '-' + parseInt(String(date1.getMonth() + 1).padStart(2, '0')) + '-' + date1.getFullYear();
          var date2 = new Date(data[1]);
          this.endDate = parseInt(date2.getDate()) + '-' + parseInt(String(date2.getMonth() + 1).padStart(2, '0')) + '-' + date2.getFullYear();
          this.initialize();
          }
        }
      }
    },
    methods: {
        initialize(){
          if(this.startDate && this.endDate){
            this.loading = true
            this.loadingStripe = true
          this.axiosCall('/dashboard/getStatistics?start_date=' + this.startDate + '&end_date=' + this.endDate, 'GET').then((res) => {
              if(res.data){
                this.loading = false
                this.loadingStripe = false
                this.data = res.data
                console.log(this.data)
                this.active_stripe_accounts_count = 0;
                this.data.stripe_stats.forEach(item => {
                  if(item.settings !== null && (item.revenue_limit > 0 || item.revenue_limit == "-1")){
                    this.active_stripe_accounts_count += 1;
                  }
                });
                if(this.data.nationalities && this.data.nationalities.app_types && this.data.nationalities.app_types.length > 0){
                this.headers_nationality = [
                  { text: 'COUNTRY', value: 'country', class: 'text-no-wrap px-2'},
                ];
                this.data.nationalities.app_types.forEach(item => {
                  this.headers_nationality.push({ text: item, value: item, align: 'end', class: 'text-no-wrap px-2' })
                });
                if(this.headers_nationality[this.headers_nationality.length - 1].value == 'Disputes'){
                    this.headers_nationality.splice(this.headers_nationality.length - 1, 0,{ text: "TOTAL", value: "total", align: 'end', class: 'text-no-wrap custom-column px-2' })
                }
                else{
                    this.headers_nationality.push({ text: "TOTAL", value: "total", align: 'end', class: 'text-no-wrap custom-column px-2' })
                }
                if(this.data.refunds && this.data.refunds.data.length > 0){
                  this.headers_nationality.push({ text: "REFUNDS", value: "refunds", align: 'end', class: 'text-no-wrap custom-column px-2' })
                }

                this.nationality_stats = this.transformData(this.data.nationalities.countries);

                let refunds = this.data.refunds.data || [];
                refunds.forEach(item => {
                  this.nationality_stats.forEach(item2 => {
                    if(item.country == item2.country){
                      item2.refunds = item.total
                      return
                    }
                  });
                });

                }
              }
              else{
                this.showToast2("Something went wrong!", 'error')
              }
          })
          }
        },
        transformData(data) {
          return Object.entries(data).map(([country, details]) => ({
            country,
            ...Object.fromEntries(
              Object.entries(details).map(([key, value]) => [
                key,
                value == "" || value == null || typeof value == undefined || value == 0 ? "" : value,
              ])
            ),
          }));
        },
        getTotalColumn1(column){
          if(column == 'country'){
            return 'Total'
          }
          else{
            let total = 0;
            this.nationality_stats.forEach(item => {
              total = total + (item[column] === "" || item[column] === null || item[column] === undefined || item[column] == '-' ? 0 :  parseFloat(item[column]))
            });
            return total;
          }
        },
        getTotalColumn2(column){
          if(column == 'mid'){
            return 'Total'
          }
          else{
            let total = 0;
            this.data.disputes_by_account.forEach(item => {
              total = total + (item[column] === "" || item[column] === null || item[column] === undefined || item[column] == '-' ? 0 :  parseFloat(item[column]))
            });
            return total;
          }
        },
        closeFormDialog(){
        this.addnl_action = '';
        this.formdata.target_nationalities = '';
        this.formdata.exclude_nationalities = '';
        this.$refs.formRef.resetValidation();
        this.dialogForm = false
       },
       async edit(item){
        await this.getNationalities();
        await this.getMids();
        this.action = 'edit';
        this.formdata = JSON.parse(JSON.stringify(item));
        this.formdata.name = item.mid;
        this.formdata.mids = JSON.parse(JSON.stringify(this.mids));
        if(item.settings_mp){
          let media_properties = item.settings_mp.split(",");
          media_properties.forEach(mp => {
            this.formdata.mids[mp] = 1;
          });
        }
        if(item.max_revenue_type){
          this.formdata.max_revenue_type = item.max_revenue_type;
        }
        else{
          this.formdata.max_revenue_type = parseFloat(item.revenue_limit) == 0 ? 'disabled' : parseFloat(item.revenue_limit) > 0 ? 'custom' : 'unlimited';
        }
        if(item.max_revenue_type == 'custom' || parseFloat(item.revenue_limit) > 0){
            this.show_custom = true;
            this.formdata.max_revenue_amount = item.revenue_limit;
          }
          else{
            this.show_custom = false;
          }
        if(parseFloat(item.instant_payout_limit) > 0){
          this.show_instant = true;
          this.formdata.instant_payout_type = 'enabled';
        }
        else{
          if(parseFloat(item.instant_payout_limit) == 0){
            this.formdata.instant_payout_type = 'disabled';
          }
          this.show_instant = false;
        }
        if(this.formdata.max_revenue_type == 'disabled'){
          this.show_instant_payout = false
          this.show_services = false
        }
        else{
          this.show_instant_payout = true
          this.show_services = true
        }
        if(item.target_nationalities){
          // console.log(item.nationalities)
          let gn = item.target_nationalities.split(',')
          let n_temp = JSON.parse(JSON.stringify(this.target_nationalities))
          for (const n in this.target_nationalities) {
            if(gn.includes(n)){
              n_temp[n] = 1;
            }
            else{
              n_temp[n] = 0;
            }
          }
          this.formdata.target_nationalities = JSON.parse(JSON.stringify(n_temp))
        }
        else{
          this.formdata.target_nationalities = JSON.parse(JSON.stringify(this.target_nationalities))
        }
        if(item.exclude_nationalities){
          // console.log(item.nationalities)
          let gn = item.exclude_nationalities.split(',')
          let n_temp = JSON.parse(JSON.stringify(this.exclude_nationalities))
          for (const n in this.exclude_nationalities) {
            if(gn.includes(n)){
              n_temp[n] = 1;
            }
            else{
              n_temp[n] = 0;
            }
          }
          this.formdata.exclude_nationalities = JSON.parse(JSON.stringify(n_temp))
        }
        else{
          this.formdata.exclude_nationalities = JSON.parse(JSON.stringify(this.exclude_nationalities))
        }
        this.formdata.show_in_accounts_list = typeof item.show_in_accounts_list !== 'undefined' && item.show_in_accounts_list != null && parseInt(item.show_in_accounts_list) == 0  ? false : true;
        this.formdata.weight = item.weight || 0;
        // await this.getAccountDetails();
        this.dialogForm = true
       },
       confirmUpdateStripeAccount(){
        if(this.$refs.formRef.validate()){
        this.loadingForm = true;
        let services = [];
        for (const mid in this.formdata.mids) {
          services.push({
            'type': mid,
            'value': this.formdata.mids[mid] ? 1 : 0,
          })
        }
        let target_nationalities = [];
        for (const n in this.formdata.target_nationalities) {
          if(this.formdata.target_nationalities[n] == 1){
            target_nationalities.push(n)
          }
        }
        let exclude_nationalities = [];
        for (const n in this.formdata.exclude_nationalities) {
          if(this.formdata.exclude_nationalities[n] == 1){
            exclude_nationalities.push(n)
          }
        }
        let data = {
          name: this.formdata.name,
          // stripe_pk: this.formdata.stripe_pk || '',
          // stripe_sk: this.formdata.stripe_sk || '',
          // stripe_account_id: this.formdata.stripe_account_id || '',
          max_revenue_type: this.formdata.max_revenue_type || '',
          max_revenue_amount: this.formdata.max_revenue_amount || '',
          services: JSON.stringify(services),
          instant_payout_type: this.formdata.instant_payout_type || '',
          instant_payout_limit: this.formdata.instant_payout_limit || '',
          target_nationalities: target_nationalities.join(','),
          exclude_nationalities: exclude_nationalities.join(','),
          show_in_accounts_list: this.formdata.show_in_accounts_list ? '' : '0',
          weight: this.formdata.weight || 0,
        };
        this.axiosCall("/config/updateAccount", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.formdata = {
              mids: JSON.parse(JSON.stringify(this.mids)),
              target_nationalities: JSON.parse(JSON.stringify(this.target_nationalities)),
              exclude_nationalities: JSON.parse(JSON.stringify(this.exclude_nationalities)),
            };
            this.getStripeStats()
            this.showToast(res.data.msg, 'success')
            this.$refs.formRef.reset();
            this.closeFormDialog();
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.loadingForm = false;
        });
        }
       },
       changeRevenue(type){
        if(type == 'custom'){
          this.show_custom = true;
        }
        else {
          this.show_custom = false
        }
        if(type == 'disabled' || this.formdata.max_revenue_amount < 0){
          this.show_instant_payout = false
          this.show_services = false
        }
        else{
          this.show_instant_payout = true
          this.show_services = true
        }
       },
       showInstant(type){
        if(type == 'enabled') {
          this.show_instant = true;
        }
        else {
          this.show_instant = false
        }
       },
       async getMids(){
        await this.axiosCall('/config/getMediaProperties', 'GET').then((res) => {
          this.mids = {};
            for (const mid of res.data) {
              this.mids[mid.media_property] = 0;
            }
          })
      },
      async getNationalities(){
        await this.axiosCall('/nationalities/getGroupNames', 'GET').then((res) => {
            this.target_nationalities = {};
            this.exclude_nationalities = {};
            for (const gn of res.data) {
              this.target_nationalities[gn.group_name] = 0;
              this.exclude_nationalities[gn.group_name] = 0;
            }
            // console.log("haha", res.data)
          })
      },
      onlyAllowNumbers(event) {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
      },
      getStripeStats(){
          if(this.startDate && this.endDate){
            this.loadingStripe = true
          this.axiosCall('/dashboard/getStripeStats?start_date=' + this.startDate + '&end_date=' + this.endDate, 'GET').then((res) => {
              if(res.data){
                this.loadingStripe = false
                this.data.stripe_stats = res.data
                this.active_stripe_accounts_count = 0;
                this.data.stripe_stats.forEach(item => {
                  if(item.settings !== null && (item.revenue_limit > 0 || item.revenue_limit == "-1")){
                    this.active_stripe_accounts_count += 1;
                  }
                });
              }
              else{
                this.showToast2("Something went wrong!", 'error')
              }
          })
          }
        },
    },
    mounted(){
        var date1 = new Date();
        this.startDate = parseInt(date1.getDate()) + '-' + parseInt(String(date1.getMonth() + 1).padStart(2, '0')) + '-' + date1.getFullYear();
        var date2 = new Date();
        this.endDate = parseInt(date2.getDate()) + '-' + parseInt(String(date2.getMonth() + 1).padStart(2, '0')) + '-' + date2.getFullYear();
        this.initialize();
        // this.getMids();
        // this.getNationalities();
    }
  }
</script>
<style scoped>
.chip-status-1{
  background-color: #e5f9f6 !important;
  color: #01c9a8 !important;
}
.chip-status-2{
  background-color: #fdf3f3 !important;
  color: #e12d2f !important;
}
.chip-status-3{
  background-color: #fbe996 !important;
  color: #413a29 !important;
}
.chip-status-4{
  background-color: #fefaf5 !important;
  color: #60b9cd !important;
}
.chip-status-5{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.stats-style h5{
  color: #8c98a4;
}
.stats-style .chip-text{
  color: #677788;
  font-weight: 600 !important;
  font-size: .8125rem !important;
}
.stats-style .chip-text-2{
  color: #677788;
  font-weight: 600 !important;
  font-size: 10.5px !important;
}
.divider-style{
  border-color: #eef0f7 !important;
}
.content-db-style{
  margin: 0 10%;
}
@media only screen and (max-width: 2000px) {
    .content-db-style{
      margin: 0;
    }
}
.v-data-table th.custom-column,
.v-data-table td.custom-column {
  background-color: lightblue !important;
}
</style>